import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs/internal/Observable';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthInterceptorService implements HttpInterceptor {
  private tokenValue = '';
  private oidcSecurityService: OidcSecurityService;

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.oidcSecurityService === undefined) {
      this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }

    if (this.oidcSecurityService !== undefined) {
      const token = this.oidcSecurityService.getToken();

      let clonedRequest;

      if (token !== '') {
        let headers = req.headers;
        if (!req.headers.get('authorization')) {
          headers = headers.append('Authorization', 'Bearer ' + token);
        }

        headers = headers.append('Content-Type', 'application/json')
          .append('x-correlation-id', Guid.create().toString());

        clonedRequest = req.clone({
          headers: headers
        });
      } else {
        clonedRequest = req.clone({});
      }

      return next.handle(clonedRequest);
    } else {
      console.log('OidcSecurityService undefined: NO auth header!');
    }
  }
}
