import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppSettings } from '../../app-settings';
//import { AppSettings } from 'src/app/app-settings';


@Injectable({
  providedIn: 'root'
})
export class CarsCommIdentityService {
  private headers = new HttpHeaders();

  constructor(private httpClient: HttpClient) { }

  getWellknownEndpoints(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return this.httpClient.get(AppSettings.WellKnownUrl, { headers: headers });
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error);
  }
}
