import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { ModalModule, BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { UserLicenseDetailsComponent } from '../shared/components/user-license-details/user-license-details.component';
import { VehicleInsuranceDetailsComponent } from '../shared/components/vehicle-insurance-details/vehicle-insurance-details.component';
import { UserProfileComponent } from '../shared/components/user-profile/user-profile.component';

@NgModule({
  imports: [
    CommonModule,
    AngularFontAwesomeModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  declarations: [ConfirmationModalComponent, SafeHtmlPipe, UserLicenseDetailsComponent, VehicleInsuranceDetailsComponent, UserProfileComponent],
  exports: [ConfirmationModalComponent, SafeHtmlPipe],
  entryComponents: [ConfirmationModalComponent],
})
export class SharedModule { }
