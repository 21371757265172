import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';


const routes: Routes = [
  {
    path: '',
    //component: AppComponent,
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      {
        path: '',
        loadChildren: './public/public.module#PublicModule',
        data: { preload: true }
      },
      {
        path: '',
        loadChildren: './account/account.module#AccountModule',
        data: { preload: true }
      },
      {
        path: '',
        loadChildren: './new-account/new-account.module#NewAccountModule',
        data: { preload: true }
      },
      { path: '**', redirectTo: '/home', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
