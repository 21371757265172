import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  message: string;
  yesCallback: () => void;
  constructor(public bsModalRef: BsModalRef) {}

  confirm (): void {
    this.yesCallback();
    this.bsModalRef.hide();
  }
}
