import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CarsCommIdentityService } from './carscomm-identity.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class WellKnownEndpointsService {
  private isLoaded = false;
  private logger = new BehaviorSubject<boolean>(false);

  constructor(
    private carsCommIdentityService: CarsCommIdentityService,
  ) { }

  public isWellKnownEndpointsLoaded(): Observable<boolean> {
    return this.logger.asObservable();
  }

  public assignLoadedValue(isLoaded: boolean) {
    this.isLoaded = isLoaded;
    this.logger.next(this.isLoaded);
  }

  public getWellKnownEndpoints(): void {
    this.carsCommIdentityService.getWellknownEndpoints()
      .subscribe(data => {
        if (data) {
          this.assignLoadedValue(true);
        }
      });
  }
}
