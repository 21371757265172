import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthModule, ConfigResult, OidcConfigService, OidcSecurityService, OpenIdConfiguration, AuthWellKnownEndpoints } from 'angular-auth-oidc-client';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSettings } from './app-settings';
import { WellKnownEndpointsService } from './shared/services/well-known-endpoints.service';
import { ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { AppInsightsService, ApplicationInsightsModule } from '@markpieszak/ng-application-insights';
import { HTTPListener, HTTPStatus } from './shared/services/interceptors/http-status-interceptor.service';
import { HttpAuthInterceptorService } from './shared/services/interceptors/http-auth-interceptor.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'ng6-toastr';

const RxJS_Services = [HTTPListener, HTTPStatus];

@NgModule({
  declarations: [
    AppComponent,
    // AdminLayoutComponent,
    // DashboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastModule.forRoot(),
    AppRoutingModule,
    NgxSpinnerModule,
    AuthModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule, 
    FormsModule,
    NgbModule,
    SharedModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKeySetlater: true
    })
    // AccountRoutingModule
  ],
  providers: [
    OidcSecurityService,
    WellKnownEndpointsService,
    RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPListener,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private wellKnownEndpointsService: WellKnownEndpointsService,
    private appInsightsService: AppInsightsService
    ) {
      this.appInsightsService.config = {
        instrumentationKey: AppSettings.ApplicationInsightsInstrumentationKey
      };
      this.appInsightsService.init();
      this.appInsightsService.trackPageView();

      this.wellKnownEndpointsService.assignLoadedValue(false);

      const openIdConfig: OpenIdConfiguration = {
        stsServer: AppSettings.StsServer,
        redirect_url: AppSettings.RedirectUrl,
        client_id: AppSettings.Client_Id,
        scope: AppSettings.Scope,
        response_type: AppSettings.ResponseType,
        silent_renew: AppSettings.SilentRenew,
        silent_renew_url: AppSettings.SilentRenewUrl,
        log_console_debug_active: false,
        post_login_route:  AppSettings.StartupRoute,
        post_logout_redirect_uri : AppSettings.PostLogoutRedirectUri,
        forbidden_route : AppSettings.ForbiddenRoute,
        unauthorized_route : AppSettings.UnauthorizedRoute,
        log_console_warning_active : AppSettings.LogConsoleWarningActive,
        max_id_token_iat_offset_allowed_in_seconds : AppSettings.MaxIdTokenIatOffsetAllowedInSeconds,
        storage: localStorage
        // all other properties you want to set
    };

      const authWellKnownEndpoints: AuthWellKnownEndpoints = {
        issuer: AppSettings.StsServer,
        jwks_uri: AppSettings.JwksUri,
        authorization_endpoint: AppSettings.AuthorizationEndpoint,
        token_endpoint: AppSettings.TokenEndpoint,
        userinfo_endpoint: AppSettings.UserinfoEndpoint,
        end_session_endpoint: AppSettings.EndSessionEndpoint,
        check_session_iframe: AppSettings.CheckSessionIframe,
        revocation_endpoint: AppSettings.RevocationEndpoint,
        introspection_endpoint: AppSettings.IntrospectEndpoint,
    };

      this.oidcSecurityService.setupModule(openIdConfig, authWellKnownEndpoints);

      this.wellKnownEndpointsService.getWellKnownEndpoints();
  }
}
