import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { HTTPStatus } from './shared/services/interceptors/http-status-interceptor.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ToastsManager } from 'ng6-toastr';
import { SignalRService } from './shared/services/signal-r.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'ClientPortal';
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private httpStatus: HTTPStatus,
    private spinner: NgxSpinnerService,
    public toastr: ToastsManager,
    public viewContainerRef: ViewContainerRef,
    public signalRService: SignalRService
    ) {      
      this.toastr.setRootViewContainerRef(viewContainerRef);
      //this.spinner.show();
      let requestCounter = 0;
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
        if (status) {
          //this.spinner.show();
          requestCounter++;
        } else {
          setTimeout(() => {
            requestCounter--;
            if (requestCounter === 0) {
              //this.spinner.hide();
            }
          }, 200);
        }
      });

      if (this.oidcSecurityService.moduleSetup) {
        this.doCallbackLogicIfRequired();
      } else {
        this.oidcSecurityService.onModuleSetup.subscribe(() => {
          this.doCallbackLogicIfRequired();
        }
      );
    }

    // this.oidcSecurityService.getIsAuthorized().subscribe(authorized => {
    //   if (authorized) {
    //     this.signalRService.startConnection();
    //     return;
    //   }
    // });
  }

  ngOnInit() {
    this.oidcSecurityService.getIsAuthorized().subscribe(authorized => {
      if (authorized) {
        this.signalRService.startConnection();  //TODO: Uncomment it
        return;
      }
    });
  }

  ngOnDestroy(): void {
    // this.oidcSecurityService.onModuleSetup.unsubscribe();
  }

  private doCallbackLogicIfRequired() {
    if (window.location.hash) {
      this.oidcSecurityService.authorizedImplicitFlowCallback();
    }
  }
}
