import { environment } from '../environments/environment';
export class AppSettings {
  // OIDC settings
  static StsServer = environment.stsServer;
  static ThisUrl = environment.thisUrl;
  static RedirectUrl: string = AppSettings.ThisUrl + '/dashboard';
  static Client_Id = 'CcClientPortal';
  static ResponseType = 'id_token token';
  static Scope = 'openid profile email offline_access';
  static PostLogoutRedirectUri: string = AppSettings.ThisUrl + '/home';
  static StartChecksession = false;
  static SilentRenew = true;
  static SilentRenewUrl = AppSettings.ThisUrl + '/silent-renew.html';
  static StartupRoute = '/dashboard';
  static ForbiddenRoute = '/forbidden';
  static UnauthorizedRoute = '/unauthorized';
  static LogConsoleWarningActive = true;
  static LogConsoleDebugActive = true;
  static MaxIdTokenIatOffsetAllowedInSeconds = 1000;

  static RegisterUrl: string = AppSettings.StsServer + '/Account/Register?returnUrl=' + AppSettings.ThisUrl + '/home';
  static ChangePasswordUrl: string = AppSettings.StsServer + '/Manage/ChangePassword?returnUrl=' + AppSettings.ThisUrl + '/dashboard';
  static WellKnownUrl: string = AppSettings.StsServer + '/.well-known/openid-configuration';
  static JwksUri: string = AppSettings.WellKnownUrl + '/jwks';
  static AuthorizationEndpoint: string = AppSettings.StsServer + '/connect/authorize';
  static TokenEndpoint: string = AppSettings.StsServer + '/connect/token';
  static UserinfoEndpoint: string = AppSettings.StsServer + '/connect/userinfo';
  static EndSessionEndpoint: string = AppSettings.StsServer + '/connect/endsession';
  static CheckSessionIframe: string = AppSettings.StsServer + '/connect/checksession';
  static RevocationEndpoint: string = AppSettings.StsServer + '/connect/revocation';
  static IntrospectEndpoint: string = AppSettings.StsServer + '/connect/introspect';

  // Api gateway
  static UserAccountApiGatewayUrl = environment.userAccountApiGatewayUrl;
  static SearchCarApiGatewayUrl = environment.searchCarApiGatewayUrl;
  static MemberCarApiGatewayUrl = environment.memberCarApiGatewayUrl;
  static MemberMessageApiGatewayUrl = environment.memberMesssageApiGatewayUrl;
  static RegoDataApiGatewayUrl = environment.regoDataApiGatewayUrl;
  static PaymentApiGatewayUrl = environment.paymentApiGatewayUrl;
  
  static ErpApiGatewayUrl = '';
  static DashApiGatewayUrl = '';
  // static TreketApiGatewayUrl = environment.treketApiGatewayUrl;
  static SignalRApiGatewayUrl = environment.signalRApiGatewayUrl;
  // application insights
  static ApplicationInsightsInstrumentationKey = environment.applicationInsightsInstrumentationKey;

  // UI Default
  static PanelDefaulBackgroundColor = '#337AB7';
  static PanelDefaulTextColor = '#FFFFFF';

  //Job related //TODO: Should be coming from DB
  static AccidentManagementJobTypeId = environment.accidentManagementJobTypeId;

  //AWS S3
  static LogAccidentBucketName = environment.logAccidentBucketName;
  static LogAccidentFolderName = environment.logAccidentFolderName;
  static VehicleCareS3BucketName = environment.vehicleCareS3BucketName;
  static VehicleCareS3FolderName = environment.vehicleCareS3FolderName;
  static MmemberMessageContainerName = environment.memberMessageContainerName;
  static AzureStorageAccountName = environment.azureStorageAccountName;
  static AzureBlobUrl = environment.azureBlobUrl;
}
