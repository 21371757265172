import { Injectable, Injector } from '@angular/core';
import * as signalR from '@aspnet/signalr'
import { SignalRModel } from '../interfaces/ISignalRModel';
import { HttpClient } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppSettings } from 'src/app/app-settings';
import {Howl} from 'howler';
import { ToastsManager } from 'ng6-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {  
  public hubConnection: signalR.HubConnection;
  sound: Howl;
  private oidcSecurityService: OidcSecurityService;

  constructor(private http: HttpClient, private injector: Injector, public toastr: ToastsManager,public router: Router){
    //this.buildConnection();
    //this.startConnection();
  }

  private buildConnection = () => {
    if (this.oidcSecurityService === undefined) {
      this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }
    const token = this.oidcSecurityService.getToken();
      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(AppSettings.SignalRApiGatewayUrl + '/messageHub', { accessTokenFactory: () => token })
      .build(); 
  };

  public startConnection = () => {   
    this.buildConnection();
        //this.hubConnection.off('vehicleCareBookingAdded', this.signalrMethodHandler);
        this.hubConnection
        .start()
        .then(() => 
        {
          console.log('Connection started');        
          this.hubConnection.on('vehicleCareBookingAdded', this.signalrMethodHandler);
        })        
        .catch(err => {
          console.log("Error while starting connection: " + err);
  
          //if you get error try to start connection again after 3 seconds.
          setTimeout(function() {
            this.startConnection();
          }, 3000);
        });
  }

  private signalrMethodHandler= (data) => {   
    this.sound = new Howl({
      src: ['assets/sounds/old_car_horn.wav']
    });   
    this.sound.play();

    this.toastr.onClickToast()
    .subscribe( toast => {            
      this.router.navigate(["/careCentre/booking-new-request/" + data.bookingId]);
    });

    this.toastr.info('Booking Ref# ' + data.bookingRefNumber, "New Booking Request", {toastLife: 8000});
    console.log('VehicleCareBookingAdded...!');
  };

}